.home-block {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

@media (max-width:780px) {
    .home-block {
        display: flex;
        flex-direction: column-reverse;
    }
}

.company-box p {
    text-align: justify;
    font-size: 13px;
    line-height: 2;
}

.ab1 {
    background-color: #BDF0ED;
    display: flex;
    text-align: justify;
}

.ab h1 {
    font-weight: bolder;
    color: white;
    margin-left: 6rem;
}

.ab2 {
    margin-left: 30px;
    margin-top: 7rem;
}

.ab3 {
    display: flex;
    text-align: justify;
}

.ab-block {
    margin-left: 3rem;
}

.ab-block h5 {
    color: gray;
}

.home-box1 {
    display: flex;
    height: auto;
    margin-left: 3rem;
}

@media (max-width:780px) {
    .home-box1 {
        display: flex;
        flex-direction: column-reverse;


    }
}


.hb {
    background-color: white;
    margin: 20px;
    margin-left: 50px;
    margin-right: 50px;
}

.hb6 {
    margin: 40px;
    padding: 25px;
    border-radius: 3px;
    background-color: white;
    color: rgb(14, 0, 0);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.hb1 {
    margin: 40px;
    padding: 20px;
    border-radius: 3px;
    background-color: white;
}

.hb1:hover {
    /* background-color: #BF1B25; */
    color: black;
}

.home-box2 {
    background-color: #60B3E4;
    padding: 20px;
}

.home-box2 h2 {
    font-weight: bold;
}

.hb2 {
    margin-left: 30px;
    margin-top: 10px;
    padding: 20px;
    border-radius: 3px;
    background-color: #f9f9f9;
}

.home-block1 {
    display: flex;
    margin-left: 70px;
}

.home-box5 {
    background-color: #0299C7;
}

.hb3 {
    margin-left: 70px;
}

.hb4 {
    margin-left: 7rem;
}

.hb5 {
    margin-left: 20px;
    background-color: white;
    margin-right: 20px;
    padding: 30px;
}









.dev-box p {
    font-size: 17px;
    text-align: justify;
}

.ch {
    color: #0299C7;
}

.co {
    background-color: #0299C7;
    color: white;
    border-radius: 5px;
}

.company-box2 p {
    text-align: justify;
}

.ab h2 {
    color: white;
    font-weight: bolder;
    left: 10%;
    top: 10rem;
    font-size: 60px;
}

.ab h3 {
    color: white;
    font-weight: bolder;
    left: 10%;
    top: 10rem;
    font-size: 60px;
}

.contact-f {
    border: none;
    border-bottom: 1px solid black;
}

.testing {
    display: flex;
}

.text,
.test p {
    text-align: justify;
}

.tQ {
    display: flex;
}

.company-boxA {
    background-image: linear-gradient(to bottom right, #0299C7, white);
}

.LearnMore {
    width: 30%;
}

.btn {
    text-decoration: none;
}

.text-imonial {
    margin: 50px auto;
}

.text-imonial h1 {
    text-align: center;
    font-weight: bold;
    padding-bottom: 10px;
    text-transform: uppercase;
}

.hed-box1 {
    padding: 20px;
    background-color: white;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}


.img-fluid {
    width: 90%;
    margin-top: 5px;
}


.banner-hed {

    background-image: linear-gradient(to bottom left, #0861fc, rgb(247, 243, 241));
    padding: 10px;
    margin: 10px;
    text-align: center;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    border-radius: 20%;

}

.icon1 {
    width: 140px;
    height: 140px;
    background: #f5f2f2;
    border-radius: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    box-shadow: 0px 5px 10px rgba(15, 15, 15, 0.5);
}

.home-web {
    font-size: 50px;
}

.home-globe {
    background-image: url(../images/globe.png);
    background-repeat: no-repeat;
    background-size: cover;
    /* background-position: bottom; */
}

.home-globe1 {
    background-color: rgba(4, 116, 116, 0.5);
    width: 100%;
    height: 70vh;
    justify-content: center;
    display: flex;
    font-size: 15px;
    color: white;
    flex-direction: column;

}

.home-globe1 h1 {
    font-weight: bold;
    margin-left: 2rem;
}

.home-globe1 p {
    margin-left: 2rem;
}

.text-decoration-none {
    text-decoration: none;
}

.Company-A {
    background-image: url("../images/swiper.png");
    background-repeat: no-repeat;
    background-attachment: fixed;

}



.box {
    position: relative;
    overflow: hidden;

}




.box:hover .box-text {
    top: 150px;
}


.box:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 320%;
    background-color: rgba(24, 149, 158, 0.7);
    top: -5%;
    left: -100%;
    z-index: 1;
    transform: rotate(0deg);
    transform-origin: center top 0;
    transition: .5s;
}

.box:hover:before {
    left: 0%;
}

.box-text {
    width: 100%;
    padding: 0 20px;
    position: absolute;
    top: -100%;
    color: white;
    left: 0;
    z-index: 2;
    transition: 1.1s;

}

@media (max-width: 780px) {
    .hed-box1 {

        display: grid;
        grid-template-columns: 1fr;
    }

    .hb2 {
        margin-left: 10px;
        margin-top: 10px;
        margin-right: 12px;
        margin-bottom: 12px;
        /* padding: 20px; */
        border-radius: 3px;
    }

    .LearnMore {
        width: 40%;
        margin-bottom: 20px;
    }

    .img-fluid {
        width: 100%;
        margin-top: 20px;
        padding-bottom: 30px;
    }

    .home-globe1 p {
        margin-left: 1rem;
    }



}

@media (max-width: 780px) {
    .hb6 {
        margin: 10px;
        padding: 20px;
        margin-bottom: 30px;
        border-radius: 3px;
        background-color: white;
        color: rgb(14, 0, 0);
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
}