*{
    margin: 0;
    padding: 0;
    font-family: "SAPRegular",Arial,Helvetica,sans-serif
}
.data h2{
   padding: 20px 10px 0px 20px;
}


.data{
    text-align: justify;
    padding: 10px;
}
.a-step_number{
    border: 2px solid #dadfe3;
    display: inline-block;
    font-size: 18px;
    font-weight: 700;
    height: 40px;
    line-height: 1;
    padding: 10px 14px;
    text-align: center;
    white-space: nowrap; 
     z-index: 1;
}
.step-up{
    display: flex;
}
.data-b{
    margin: 10px;
    width: 300px;
    color: black;
}
.data-b h4{
    font-size: 20px;
}
.react-box p,
.data-b p{
    text-align: justify;
}