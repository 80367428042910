* {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
}

.head {
    background-color: #f3f3f3;
    width: 100%;

}

.head-btn {
    background-color: white;
}

.logo {
    padding-top: 2px;
    width: 120px;
    padding-bottom: 10px;
}

.navbar {
    background-color: white;
    color: black;
    /* height: 4.8rem; */
    box-shadow: none;
    padding-left: 0px;
    padding-bottom: 5px;
    width: 100%;
}

.navbar-nav {
    background-color: transparent;
    width: 100%;

}

.navbar-collapse {
    z-index: 3;
}

.navbar-brand h3 {
    font-family: 'Open Sans', sans-serif;
    font-size: 25px;
}

.navbar-nav .nav-item h6 {
    /* font-family: 'Open Sans', sans-serif; */
    font-size: 17px;
    font-weight: 500;
}

.dropdown:hover .dropdown-menu {
    display: block;

}

.menu-area {
    position: static;
}

.mega-area {
    position: absolute;
    width: 93%;
    left: 3rem;
    box-sizing: border-box;
    padding: 0.5rem;
    border-radius: 0;
    top: 4.5rem;

}

.h1 {
    display: flex;

    /* padding: 10px 50px 50px 50px ; */
}

@media(max-width:384px) {
    .h1 {
        display: flex;
        flex-direction: column;
    }

    .mega-area {
        place-items: center;
    }

    .nav-item h5 {
        background-color: none;
    }
}

.h1 .h2 h6 {
    padding-top: 15px;
    font-size: 10px;
    /* font:normal normal  15px Brandon Grotesque; */
}

.h2 p {
    font-size: 13px;
    font-weight: 100;

}

.dropdown-item {
    /* font:normal normal  13px Brandon Grotesque; */
    font-size: 13px;
}

.navbar-nav .nav-item {
    box-sizing: border-box;
    margin-left: 2rem;
}

.nav-item {
    list-style-type: none;
}

/* .nav-item:hover{
    border-bottom: 5px solid #e3d6ff;
    width: 80px;
} */