
.HoverEffect:hover img {
    transform: scale(1.2);
    opacity: 0.5;
    transition:  5s ;
    cursor: pointer;
}

.HoverEffect {
    overflow: hidden;
    background-color: rgba(0, 0, 0, 1); 
   
}

.HoverEffectVIDEO:hover video {
    transform: scale(1.1);
    opacity: 0.5;
    transition: all 5s;
    cursor: pointer;
}

.HoverEffectVIDEO {
    overflow: hidden;
    background-color: rgba(0, 0, 0, 1);
}




 /* /////   Font Family    ////// */


 
.fontFamilyHeader {
    font-family: 'Kanit', sans-serif;
    font-weight: 600;
}

.fontFamilyHeader1 {
    font-family: 'Kanit', sans-serif;
}

.SolutionDropDownFontFamily {
    font-family: 'Kanit', sans-serif;
}

.SolutionDropDownFontFamily1 {
    font-family: 'Kanit', sans-serif;
    line-height: 40px;
}

.SolutionDropDownFontFamily2 {
    font-family: 'Kanit', sans-serif;
    line-height: 23px;
}

.SolutionDropDown {
    font-family: 'Roboto', sans-serif;
}


.h1FontFamily {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 900;
}

/* ///     FONT FAMILY END   /// */

.widthRESOPNSIVE{
    width: 50%;
}



@media only screen and (max-width: 1200px) {
    .widthRESOPNSIVE{
        width:100%;
    }
   
 
    
}