.footer-bg{
    background-color: #232f50;
    color: white;
    padding: 10px;
}
.logo img{
    width: 180px;
}
.hr{
    border-bottom: 1px solid white ;
    width: 97%;
    margin-left: 20px;
}
.fo{
    color: #232f50;
}
@media (max-width:384px){
    .footer-box{
       display: flex;
       flex-direction: column;
    }
}
@media (max-width:390px){
    .footer-box{
       display: flex;
       flex-direction: column;
    }
}
@media (max-width:414px){
    .footer-box{
       display: flex;
       flex-direction: column;
    }
}
@media (max-width:540px){
    .footer-box{
       display: flex;
       flex-direction: column;
    }
}