* {
    margin: 0px;
    padding: 0px;
}

.Contact-box {
    padding-top: 164px;
    position: relative;
    padding-bottom: 40px;

}

.banner-contact {
    background-color: white;
    text-align: center;
    width: 100%;
    max-height: 100%;
}

.banner-contact h2 {
    /* font-weight: bold; */
    font-size: 40px;
    line-height: 72px;
    text-align: center;

}

.info-text {
    text-align: center;
    font-size: 20px;
    color: black;

}

.Contact-box1 {
    padding: 20px;
    background-color: white;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.banner-contact1 {
    padding: 10px;
    margin: 20px;
    text-align: center;
    /* box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2); */
}

@media (max-width: 780px) {
    .Contact-box1 {
        display: grid;
        grid-template-columns: 1fr;
    }
}

.icon {
    width: 184px;
    height: 184px;
    background: #F6F6F6;
    border-radius: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
}


.banner-contact1 p {
    padding-top: 20px;
    color: #888888;
}

form {
    margin: 35px;
}

.contact-f {
    width: 100%;
}

.input-f {
    width: 500px;
    height: 40px;
    margin-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid #777;
    border-radius: 14px;


}

.textarea-field {
    height: 150px;
    padding-top: 10px;
}

.btn {
    border-radius: 20px;
    color: #fcfcfc;
    margin-top: 18px;
    padding: 1S0px;
    border: 1px solid black;
    background-color: red;
}

@media (max-width: 780px) {
    .input-f {
        width: 100%;

    }

}

.amar {
    float: right;
    width: 650px;
    height: 400px;

}

@media (max-width: 780px) {
    .amar {

        margin-right: 0px;
        margin: 0px;
    }
}

@media (max-width: 767px) {
    .Contact-box1 {
        padding: 0px;
        background-color: white;
        display: grid;
        grid-template-columns: 1fr;
    }
}