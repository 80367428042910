*{
    margin: 0;
    padding: 0;
    font-family: "SAPRegular",Arial,Helvetica,sans-serif;

}
.big-box{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
@media(max-width:384px){
    .big-box{
        display: grid;
        grid-template-columns: 1fr;
    }
    .sharepoint-box,
    .pa,
    .bc{
        display: flex;
        flex-direction: column;
    }
    .blockbox{
      width: 228%;
    }
    .blockbox img{
        width: 300px;
    }
    .react-box{
      width: 87%;
    }
    .ar-box1 img{
        min-width: 150px;
    }
}
@media(max-width:414px){
    .big-box{
        display: grid;
        grid-template-columns: 1fr;
    }
    .sharepoint-box,
    .bc{
        display: flex;
        flex-direction: column;
    }
    .blockbox{
      width: 228%;
    }
    .blockbox img{
        width: 300px;
    }
    .react-box{
        width: 87%;
      }
      .pa{
        display: flex;
        flex-direction: column;
    }
    .pa1{
        width: 200%;
    }
}
@media(max-width:820px){
   .sharepoint-box,
    .bc{
        display: flex;
        flex-direction: column;
    }
    .pa{
        display: flex;
        flex-direction: column;
    }
    .blockbox{
      width: 228%;
    }
    .blockbox img{
        width: 20rem;
    }
    .pa1{
        width: 200%;
    }
}
@media(max-width:768px){
    .sharepoint-box,
    .big-box{
        display: grid;
        grid-template-columns: 1fr;
    }
    .dot-box2 ,
    .java-box2,
    .java-box3{
        display: flex;
        flex-direction: column;
    }
    
}
@media(max-width:990px){
    .sharepoint-box,
    .big-box{
        display: grid;
        grid-template-columns: 1fr ;
    }
    .pa1{
        width: 200%;
    }
    
}
@media(max-width:390px){
   .js{
    margin-top: 30px;
   }
   .pa1{
    width: 200%;
   }
    .sharepoint-box{
    display: flex;
    flex-direction: column;
    }
    
}
.blockbox{
    color: black;
}
.bc{
    display: flex;
}